import { IResult, Page, Pageable } from '@astick/core';
import { Franchisees, Pvz } from '../models';
import { PvzRepository } from '../repositories/pvz.repository';

/** Сервис работы c ПВЗ */
export class PvzService {
	//#region Franchisees

	static async getFranchisees(pageable: Pageable): Promise<IResult<Page<Franchisees>>> {
		return PvzRepository.getFranchisees(pageable);
	}

	static async createFranchisees(franchisees: Franchisees): Promise<IResult<Franchisees>> {
		return PvzRepository.createFranchisees(franchisees);
	}

	static async updateFranchisees(franchisees: Franchisees): Promise<IResult<Franchisees>> {
		return PvzRepository.updateFranchisees(franchisees);
	}

	static async deleteFranchisees(franchiseesId: string): Promise<IResult> {
		return PvzRepository.deleteFranchisees(franchiseesId);
	}

	static async activateFranchisees(franchiseesId: string): Promise<IResult> {
		return PvzRepository.activateFranchisees(franchiseesId);
	}

	static async deactivateFranchisees(franchiseesId: string): Promise<IResult> {
		return PvzRepository.deactivateFranchisees(franchiseesId);
	}

	static async lockFranchisees(franchiseesId: string): Promise<IResult> {
		return PvzRepository.lockFranchisees(franchiseesId);
	}

	static async unlockFranchisees(franchiseesId: string): Promise<IResult> {
		return PvzRepository.unlockFranchisees(franchiseesId);
	}

	//#endregion

	static async getPvz(pageable: Pageable): Promise<IResult<Page<Pvz>>> {
		return PvzRepository.getPvz(pageable);
	}

	static async createPvz(pvz: Pvz): Promise<IResult<Pvz>> {
		return PvzRepository.createPvz(pvz);
	}

	static async updatePvz(pvz: Pvz): Promise<IResult<Pvz>> {
		return PvzRepository.updatePvz(pvz);
	}

	static async deletePvz(pvzId: string): Promise<IResult> {
		return PvzRepository.deletePvz(pvzId);
	}
}
