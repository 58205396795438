import { Address } from '@astick/core';

export const AddressUtils = {
	getAddressString: (address?: Address, withHouseNumber: boolean = true, isReverse: boolean = false) => {
		if (!address) {
			return '-';
		}
		const adrString: string[] = [];
		if (address.country) {
			adrString.push(address.country);
		}
		if (address.region) {
			adrString.push(address.region);
		}
		if (address.county) {
			adrString.push(address.county);
		}
		if (address.city) {
			adrString.push(`г. ${address.city}`);
		}
		if (address.district) {
			adrString.push(address.district);
		}
		if (address.street) {
			adrString.push(address.street);
		}
		if (withHouseNumber && address.building) {
			adrString.push(address.building);
		}
		if (withHouseNumber && address.houseNumber) {
			adrString.push(address.houseNumber);
		}
		return isReverse ? adrString.reverse().join(', ') : adrString.join(', ');
	},
	validAddress: (address: any): boolean => {
		if (!address.lat) {
			alert('Не указана широта');
			return false;
		}
		if (!address.lon) {
			alert('Не указана долгота');
			return false;
		}
		const lat = parseFloat(address.lat);
		if (lat <= 0) {
			alert('Неверное значение широты');
			return false;
		}
		const lon = parseFloat(address.lon);
		if (lon <= 0) {
			alert('Неверное значение долготы');
			return false;
		}
		if (!address.country) {
			alert('Не указана страна');
			return false;
		}
		if (!address.region) {
			alert('Не указан регион');
			return false;
		}
		if (!address.city) {
			alert('Не указан город');
			return false;
		}
		if (!address.street) {
			alert('Не указана улица');
			return false;
		}
		if (!address.building) {
			alert('Не указано строение');
			return false;
		}
		return true;
	},
};
