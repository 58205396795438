import { useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Button, Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { ControlProps, FormikUtils } from '@astick/ui';
import { useToast } from 'src/hooks';
import { User } from 'src/models';
import { IconMain } from 'src/resources/icons';
import { SelectUserWidget, SelectUserWidgetRef } from '../../widgets/selectUser.widgets';
import { UsersService } from '../../services/users.service';

export type SelectUserFormProps = ControlProps & FormControlProps & {
	name: string;
	label?: string | React.ReactNode;
	onChangeValue?: (value) => void;
};

export const SelectUserForm = (props: SelectUserFormProps) => {
	const _props = { ...props };
	delete _props.onChangeValue;

	const toast = useToast();

	const usersRef = useRef<SelectUserWidgetRef>(null);

	const [f, meta, helpers] = useField(props.name);
	const { validationSchema } = useFormikContext();
	const field: any = { ...f };
	delete field.onChange;

	const isRequired = FormikUtils.isRequiredField(validationSchema, props.name);
	const label = `${props.label}${isRequired ? '*' : ''}`;
	
	const [selectUser, setSelectUser] = useState<User>();

	useEffect(() => {
		const loadUser = async () => {
			if (!f.value) {
				return;
			}
			const r = await UsersService.getUser(f.value);
			if (r.success) {
				setSelectUser(r.value);
			} else {
				toast.addError('Не удалось получить список пользователей!');
			}
		};
		loadUser();
	}, [f.value]);

	return (
		<div className={`mb-3 ${props.className}`} style={{ ...props.style, position: 'relative' }}>
			<SelectUserWidget ref={usersRef} onSubmit={async (user) => {
				setSelectUser(user);
				helpers.setValue(user.id);
				props.onChangeValue?.(user.id);
			}} />
			{label ? <div className="label">{label}</div> : null}
			<InputGroup className="mb-3">
				<Form.Control
					{..._props}
					{...field}
					className={props.onClick ? 'clickable' : ''}
					name={props.name}
					readOnly={true}
					value={selectUser ? `${User.getInitials(selectUser)} (${selectUser.username})` : '-'}
					isInvalid={props.isInvalid || (meta.touched && !!meta.error)}
				/>
				<Button title="Выберите пользователя" variant="outline-secondary">
					<IconMain type="user" onClick={() => usersRef.current?.open('Выбор пользователя', selectUser)} />
				</Button>
				<Form.Control.Feedback type="invalid">
					{meta.error}
				</Form.Control.Feedback>
			</InputGroup>
		</div>
	);
};

SelectUserForm.displayName = 'SelectUserForm';
