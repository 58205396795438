import { useEffect, useRef, useState } from 'react';
import maplibregl, { NavigationControl } from 'maplibre-gl';
import { Map, MapInstance, MapRef } from '@vis.gl/react-maplibre';
import layersTheme from 'protomaps-themes-base';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { Address } from '@astick/core';
import { AddressUtils } from '@astick/ui';

import 'maplibre-gl/dist/maplibre-gl.css';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import './map.control.scss';

const geocoderApi = {
	forwardGeocode: async (config) => {
		const features: any[] = [];
		try {
			const request = `${process.env.REACT_APP_MAP}/geocoding/search/byText?text=${config.query}`;
			const response = await fetch(request);
			const addresses = await response.json() as Address[];
			for (const address of addresses) {
				const center = [
					address.coordinates?.longitude,
					address.coordinates?.latitude,
				];
				const addressText = AddressUtils.getAddressString(address, false, true);
				const point = {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: center,
					},
					place_name: addressText,
					properties: address,
					text: addressText,
					place_type: ['place'],
					center,
				};
				features.push(point);
			}
		} catch (e) {
			console.error(`Failed to forwardGeocode with error: ${e}`);
		}
		return {
			features,
		};
	},
};

export type Coordinates = {
	longitude: number;
	latitude: number;
}

export type MapControlProps = {
	/** Отображение панели поиска */
	isGeocoderApi?: boolean;
	/** Отображение кнопки на полный экран */
	isFullscreen?: boolean;
	/** Отображение навигационной панели */
	isNavigationControl?: boolean;
	/** Определение местоположения по геолокации */
	isUserLocation?: boolean;
	coordinates?: Coordinates;
	setCoordinates?: ({
		longitude,
		latitude,
	}: Coordinates) => void;
	className?: string;
}

export const MapControl = (props: MapControlProps) => {
	const [map, setMap] = useState<MapInstance>();
	const mapRef = useRef<MapRef>();

	const initMap = () => {
		if (!map) {
			return;
		}
		if (props.isGeocoderApi) {
			map.addControl(
				new MaplibreGeocoder(geocoderApi as any, {
					maplibregl,
				}),
			);
		}
		if (props.isFullscreen) {
			map.addControl(new maplibregl.FullscreenControl());
		}
		if (props.isNavigationControl) {
			const nav = new NavigationControl();
			map.addControl(nav, 'top-left');
		}
		if (props.isUserLocation) {
			map.addControl(
				new maplibregl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
					trackUserLocation: true,
				}),
			);
		}

		const marker = new maplibregl.Marker();
		if (props.coordinates) {
			marker.setLngLat([
				props.coordinates.longitude,
				props.coordinates.latitude,
			]);
			marker.addTo(map);
		}

		map.on('click', (e) => {
			marker.setLngLat([
				e.lngLat.lng,
				e.lngLat.lat,
			]);
			marker.addTo(map);
			props.setCoordinates?.({
				longitude: e.lngLat.lng,
				latitude: e.lngLat.lat,
			});
		});
	};

	useEffect(() => {
		if (!map) {
			return;
		}
		initMap();
	}, [map]);

	return (
		<div className={`map-control${props.className ? ` ${props.className}` : ''}`}>
			<Map
				ref={(ref) => {
					if (ref) {
						mapRef.current = ref;
						const map = ref.getMap();
						map.on('load', () => {
							setMap(ref.getMap());
						});
					}
				}}
				mapLib={maplibregl}
				mapStyle={{
					version: 8,
					glyphs: `${process.env.REACT_APP_MAP}/fonts/{fontstack}/{range}.pbf`,
					sprite: `${process.env.REACT_APP_MAP}/sprites/v4/light`,
					center: props.coordinates?.longitude || props.coordinates?.latitude ? [props.coordinates.longitude, props.coordinates.latitude] : [37.6156, 55.7522], // Москва
					zoom: 16,
					projection: {
						type: 'globe',
					},
					sources: {
						protomaps: {
							type: 'vector',
							url: `${process.env.REACT_APP_MAP}/tiles/tiles.json`,
							attribution: '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
						},
					},
					layers: [
						...layersTheme('protomaps', 'light', 'ru'),
						{
							id: 'housenumbers',
							type: 'symbol',
							source: 'protomaps',
							'source-layer': 'buildings',
							minzoom: 14,
							layout: {
								'text-field': ['get', 'house_number'],
								'text-font': ['Noto Sans Regular'],
								'text-size': 10,
								'text-anchor': 'center',
								'text-offset': [0, 0.1],
							},
							paint: {
								'text-color': '#333',
								'text-halo-color': '#fff',
								'text-halo-width': 1,
							},
						},
					],
				}}
			/>
		</div>
	);
};
