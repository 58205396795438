/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import { TableTypeColumn } from '@astick/ui';
import { MapPosition } from '../map/mapPosition';

/** Модель авторизации пользователя */
export type UserUL = {
    /** Краткое название */
    briefName: string;

    /** Полное название */
    name: string;

    /** Координаты */
    coordinates: MapPosition;

    /** Почтовый индекс */
    index: string;

    /** ИНН */
    inn: string;

    //#region IP

    /** ОГРН */
    ogrn?: string;

    /** ОКПО */
    okpo?: string;

    //#endregion

    //#region Orq

    /** КПП */
    kpp?: string;

    /** ОКАТО */
    okato?: string;

    /** ОКОГУ */
    okogu?: string;

    //#endregion

    //#region Bank

    /** Наименование банка */
    bankName?: string;

    /** Счет */
    bankAccount?: string;

    /** Кор. счет банка */
    bankCorrAccount?: string;

    /** Город банка */
    bankCity?: string;

    /** БИК банка */
    bankBIK?: string;

    /** ИНН банка */
    bankINN?: string;

    /** КПП банка */
    bankKPP?: string;

    //#endregion

    /** Активированный */
    activated: boolean;

    /** Заблокированный */
    locked: boolean;
}

export const userULSchema = {
	briefName: yup.string().min(3, 'Минимальная длина краткого названия ${min} буквы.')
		.max(255, 'Максимальная длина краткого названия ${max} буквы.').required('Необходимо ввести краткое название'),
	name: yup.string().min(3, 'Минимальная длина полного названия ${min} буквы.')
		.max(255, 'Максимальная длина полного названия ${max} буквы.').required('Необходимо ввести полное название'),
	coordinates: yup.object().nullable().default(null).required('Необходимо указать координаты расположения ЮЛ'),
	index: yup.string().length(6, 'Длина почтового индекса 6 цифр.').required('Необходимо ввести почтовый индекс'),
	inn: yup.string().min(10, 'Минимальная длина ИНН ${min} цифр.')
		.max(12, 'Максимальная длина ИНН ${max} цифр.').required('Необходимо ввести ИНН'),
	ogrn: yup.string().min(13, 'Минимальная длина ОГРН ${min} цифр.')
		.max(15, 'Максимальная длина ОГРН ${max} цифр.').required('Необходимо ввести ОГРН'),
	okpo: yup.string().min(8, 'Минимальная длина ОКПО ${min} цифр.')
		.max(10, 'Максимальная длина ОКПО ${max} цифр.').required('Необходимо ввести ОКПО'),
	kpp: yup.string().length(9, 'Длина КПП 9 цифр.').required('Необходимо ввести КПП'),
	okato: yup.string().min(2, 'Минимальная длина ОКАТО ${min} цифры.')
		.max(11, 'Максимальная длина ОКАТО ${max} цифр.').required('Необходимо ввести ОКАТО'),
	okogu: yup.string().length(7, 'Длина OKOGU 7 цифр.').required('Необходимо ввести OKOGU'),
	bankName: yup.string().min(2, 'Минимальная длина наименования банка ${min} буквы.').required('Необходимо ввести наименование банка'),
	bankAccount: yup.string().length(20, 'Длина счета 20 цифр.').required('Необходимо ввести счет'),
	bankCorrAccount: yup.string().length(20, 'Длина кор. счета банка 20 цифр.').required('Необходимо ввести кор. счет банка'),
	bankCity: yup.string().min(2, 'Минимальная длина города банка ${min} буквы.')
		.max(50, 'Максимальная длина города банка ${max} буквы.').required('Необходимо ввести город банка'),
	bankBIK: yup.string().length(9, 'Длина БИК банка 9 цифр.').required('Необходимо ввести БИК банка'),
	bankINN: yup.string().min(10, 'Минимальная длина ИНН банка ${min} цифр.')
		.max(12, 'Максимальная длина ИНН банка ${max} цифр.').required('Необходимо ввести ИНН банка'),
	bankKPP: yup.string().length(9, 'Длина КПП банка 9 цифр.').required('Необходимо ввести КПП банка'),
	activated: yup.boolean().default(false),
	locked: yup.boolean().default(false),
};

export const columnsUL: TableTypeColumn[] = [
	{ name: 'briefName', type: 'text', header: 'Краткое название', minWidth: 100 },
	{ name: 'name', type: 'text', header: 'Полное название', defaultVisible: false },
	{ name: 'coordinates', type: 'coordinates', header: 'Координаты', defaultVisible: false, rowInDialog: 0 },
	{ name: 'index', type: 'text', header: 'Почтовый индекс', defaultVisible: false, rowInDialog: 0 },
	{ name: 'inn', type: 'text', header: 'ИНН', minWidth: 80, rowInDialog: 1 },
	{ name: 'ogrn', type: 'text', header: 'ОГРН', defaultVisible: false, rowInDialog: 1 },
	{ name: 'okpo', type: 'text', header: 'ОКПО', defaultVisible: false, rowInDialog: 1 },
	{ name: 'kpp', type: 'text', header: 'КПП', defaultVisible: false, rowInDialog: 2 },
	{ name: 'okato', type: 'text', header: 'ОКАТО', defaultVisible: false, rowInDialog: 2 },
	{ name: 'okogu', type: 'text', header: 'ОКОГУ', defaultVisible: false, rowInDialog: 2 },
	{ name: 'bankName', type: 'text', header: 'Наименование банка', defaultVisible: false, rowInDialog: 3 },
	{ name: 'bankCity', type: 'text', header: 'Город банка', defaultVisible: false, rowInDialog: 3 },
	{ name: 'bankAccount', type: 'text', header: 'Счет', defaultVisible: false, rowInDialog: 4 },
	{ name: 'bankCorrAccount', type: 'text', header: 'Кор. счет банка', defaultVisible: false, rowInDialog: 4 },
	{ name: 'bankBIK', type: 'text', header: 'БИК банка', defaultVisible: false, rowInDialog: 5 },
	{ name: 'bankINN', type: 'text', header: 'ИНН банка', defaultVisible: false, rowInDialog: 5 },
	{ name: 'bankKPP', type: 'text', header: 'КПП банка', defaultVisible: false, rowInDialog: 5 },
	{ name: 'activated', type: 'boolean', header: 'Активированный', rowInDialog: 6 },
	{ name: 'locked', type: 'boolean', header: 'Заблокированный', rowInDialog: 6 },
];