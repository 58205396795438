import { Route, Routes } from 'react-router-dom';
import { MainLayout } from '../layouts/main.layout';
import { CartPage, ContactsPage, HomePage, MapsPage } from '../pages/main';
import { AuthPage, RegisterPage, RequestResetPasswordPage, ResetPasswordPage } from '../pages/account';
import { CatalogPage, CatalogsPage, CategoriesPage } from '../pages/catalogs';
import { SearchCatalogsPage, SearchProductsPage } from '../pages/search';
import { VerifyEmailPage } from '../pages/account/verifyEmail.page';
import { AdminRoutes } from '../areas';

export const MainRoutes = () => {
	return <Routes>
		<Route element={<MainLayout />}>
			<Route path="/" element={<HomePage />} />
			<Route path="/home" element={<HomePage />} />
			<Route path="/account/requestResetPassword" element={<RequestResetPasswordPage />} />
			<Route path="/account/resetPassword" element={<ResetPasswordPage />} />
			<Route path="/account/auth" element={<AuthPage />} />
			<Route path="/account/register" element={<RegisterPage />} />
			<Route path="/account/verifyEmail" element={<VerifyEmailPage />} />
			<Route path="/contacts" element={<ContactsPage />} />
			<Route path="/search" element={<SearchCatalogsPage />} />
			<Route path="/search/products" element={<SearchProductsPage />} />
			<Route path="/cart" element={<CartPage />} />
			<Route path="/maps" element={<MapsPage />} />
		</Route>
		<Route path="/catalogs" element={<CatalogsPage />} />
		<Route path="/catalogs/:brand" element={<CatalogPage />} />
		<Route path="/catalogs/:brand/:modelId/*" element={<CategoriesPage />} />
		<Route path="/admin/*" element={<AdminRoutes />} />
	</Routes>;
};
