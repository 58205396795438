import { memo, useMemo, useState } from 'react';
import { useField } from 'formik';
import { Button, Form, FormControlProps, InputGroup, Modal } from 'react-bootstrap';
import { ControlProps } from '../models';
import { useFormikContext } from '../../hooks';
import { FormikUtils } from '../../utils/formik.utils';
import { ControlsIcons } from '../../resources/icons';
import { Coordinates, MapControl, MapControlProps } from '../map.control';

export type CoordinatesFormProps = ControlProps & FormControlProps & {
	name: string;
	label?: string | React.ReactNode;
	/** Наименование атрибута широты */
	latitudeProperty?: string;
	/** Наименование атрибута долготы */
	longitudeProperty?: string;
	rows?: number;
	map?: MapControlProps;
	onChangeValue?: (value) => void;
};

const MapControlWithOneProps = ({
	mapProps,
	coordinates,
	setCoordinates,
}: {
	mapProps?: MapControlProps,
	coordinates: Coordinates,
	setCoordinates?: ({
		longitude,
		latitude,
	}) => void
}) => {
	return <MapControl {...mapProps} coordinates={coordinates} setCoordinates={setCoordinates} />;
};

const MapControlMemo = memo(MapControlWithOneProps);

export const CoordinatesForm = (props: CoordinatesFormProps) => {
	const { latitudeProperty = 'latitude', longitudeProperty = 'longitude' } = props;

	const _props = { ...props };
	delete _props.onChangeValue;

	const [f, meta, helpers] = useField(props.name);
	const { validationSchema } = useFormikContext();
	const field: any = { ...f };
	delete field.onChange;

	const isRequired = FormikUtils.isRequiredField(validationSchema, props.name);
	const label = `${props.label}${isRequired ? '*' : ''}`;

	const [showMap, setShowMap] = useState(false);
	const [coordinates, setCoordinates] = useState<Coordinates>({
		longitude: f.value?.[longitudeProperty] as number ?? 0,
		latitude: f.value?.[latitudeProperty] as number ?? 0,
	});
	const [coordinatesSelected, setCoordinatesSelected] = useState<Coordinates>(coordinates);

	const handleClose = () => setShowMap(false);

	const coordinatesMemo = useMemo(
		() => {
			return coordinates;
		},
		[coordinates],
	);

	return (
		<div className={`mb-3 ${props.className}`} style={{ ...props.style, position: 'relative' }}>
			<Modal size="xl" show={showMap} fullscreen="xxl-down" onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Укажите местоположение на карте</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<MapControlMemo coordinates={coordinatesMemo} setCoordinates={setCoordinatesSelected} mapProps={props.map} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => {
						setCoordinatesSelected(coordinates);
						handleClose();
					}}>Закрыть</Button>
					<Button variant="primary" disabled={!coordinatesSelected.longitude || !coordinatesSelected.latitude} onClick={() => {
						setCoordinates(coordinatesSelected);
						helpers.setValue(coordinatesSelected);
						props.onChangeValue?.(coordinatesSelected);
						handleClose();
					}}>Выбрать</Button>
				</Modal.Footer>
			</Modal>
			{label ? <div className="label">{label}</div> : null}
			<InputGroup className="mb-3">
				<Form.Control
					{..._props}
					{...field}
					className={props.onClick ? 'clickable' : ''}
					name={props.name}
					readOnly={true}
					value={coordinates.latitude && coordinates.longitude ? `ш.: ${coordinates.latitude}, д.: ${coordinates.longitude}` : '-'}
					isInvalid={props.isInvalid || (meta.touched && !!meta.error)}
				/>
				<Button title="Указать на карте" variant="outline-secondary">
					<ControlsIcons type="coordinates" onClick={() => setShowMap(true)} />
				</Button>
				<Form.Control.Feedback type="invalid">
					{meta.error}
				</Form.Control.Feedback>
			</InputGroup>
		</div>
	);
};

CoordinatesForm.displayName = 'CoordinatesForm';
