// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-control {
  height: 100%;
  width: 100%;
}

@media (min-width: 1440px) {
  .map-control {
    min-height: 600px;
    display: grid;
  }
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/components/map.control.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE;IACE,iBAAA;IACA,aAAA;EACF;AACF","sourcesContent":[".map-control {\n  height: 100%;\n  width: 100%;\n}\n\n@media (min-width: 1440px) {\n  .map-control {\n    min-height: 600px;\n    display: grid;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
