import { IResult, Pageable, Page } from '@astick/core';
import { Role, User } from 'src/models';
import { RolesRepository } from 'src/repositories/roles.repository';
import { UsersRepository } from 'src/repositories/users.repository';

export class UsersService {
	static async getUser(userId: string): Promise<IResult<User>> {
		return UsersRepository.getUser(userId);
	}

	static async getUsers(paging: Pageable): Promise<IResult<Page<User>>> {
		return UsersRepository.getUsers(paging);
	}

	static async deleteUser(userId: string): Promise<IResult> {
		return UsersRepository.deleteUser(userId);
	}

	static async getRoles(paging: Pageable): Promise<IResult<Page<Role>>> {
		return RolesRepository.getRoles(paging);
	}

	static async createRole(role: Role): Promise<IResult<Role>> {
		return RolesRepository.createRole(role);
	}

	static async updateRole(role: Role): Promise<IResult<Role>> {
		return RolesRepository.updateRole(role);
	}

	static async deleteRole(roleId: string): Promise<IResult> {
		return RolesRepository.deleteRole(roleId);
	}

	static async getUserRoles(userId: string): Promise<IResult<string[]>> {
		return RolesRepository.getUserRoles(userId);
	}

	static async setUserRoles(userId: string, roles: string[]): Promise<IResult> {
		return RolesRepository.setUserRoles(userId, roles);
	}
};