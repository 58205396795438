/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import { UserUL, userULSchema } from '../account/ul.model';

/** Класс модели Франчайзи */
export type Franchisees = UserUL & {
	/** ID Франчайзи */
	id: string;

	/** Код франчайзи */
	code: string;

	/** Пользователь франчайзи */
	userId: string;

	/** Комиссия за услугу */
	fee: number;
}

export const franchiseesSchema = yup.object({
	...userULSchema,
	code: yup.string().min(3, 'Минимальная длина кода ${min} буквы.').max(30, 'Максимальная длина кода ${min} буквы.').required('Необходимо ввести код'),
	userId: yup.string().required('Необходимо ввести пользователя'),
	fee: yup.number().min(0, 'Комиссия должна быть положительной').default(0).required('Необходимо ввести комиссию'),
});
