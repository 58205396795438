import moment from 'moment';
import { IResult, Page, Pageable } from '@astick/core/src/models';
import { TypeSingleFilterValue } from '../components';

export class SpringUtils {
	private static getPageFromData<T>(dataPage): Page<T> {
		const page: Page<T> = {
			limit: 0,
			offset: 0,
			size: 0,
			totalSize: 0,
			items: [],
		};
		if (!dataPage?.content) {
			return page;
		}
		page.limit = dataPage.pageable?.pageSize;
		page.offset = dataPage.pageable?.offset;
		page.size = dataPage.size;
		page.totalSize = dataPage.totalElements;
		return page;
	}

	public static getPage<T>(result: IResult): Page<T> {
		if (!result?.success) {
			return this.getPageFromData<T>(undefined);
		}
		const page = this.getPageFromData<T>(result.value);
		const values = result.value.content ? result.value.content : Array.isArray(result.value) ? result.value : [];
		for (const val of values) {
			const item = {} as any;
			Object.assign(item, val);
			page.items.push(item);
		}
		return page;
	}

	public static getFilters(filterValue: TypeSingleFilterValue[], ignoreOperator = false): {
		[p: string]: string | string[];
	} | undefined {
		if (!filterValue) {
			return;
		}
		const filters: {
			[p: string]: string | string[];
		} = {};
		(Array.isArray(filterValue) ? filterValue : [filterValue]).forEach((filter) => {
			if (filter.value === undefined || filter.value === null) {
				return;
			}
			const name = filter.path ?? filter.name;
			if (['select', 'boolean'].includes(filter.type)) {
				filters[name] = filter.value;
			} else if (filter.type === 'string') {
				if (ignoreOperator) {
					filters[name] = filter.value;
				} else if (filter.operator === 'contains') {
					filters[name] = `%${filter.value}%`;
				} else if (filter.operator === 'eq') {
					filters[name] = filter.value;
				} else if (filter.operator === 'startsWith') {
					filters[name] = `${filter.value}%`;
				} else if (filter.operator === 'endsWith') {
					filters[name] = `%${filter.value}`;
				}
			} if (filter.type === 'number') {
				filters[name] = filter.value;
			} else if (filter.type === 'date') {
				if (!filter.value || filter.value === '-' || filter.value === '') {
					return;
				}
				if (filter.operator === 'afterOrOn') {
					filters[name + 'Min'] = moment(filter.value, 'L').toISOString();
				} else if (filter.operator === 'beforeOrOn') {
					filters[name + 'Max'] = moment(filter.value, 'L').toISOString();
				} else if (filter.operator === 'eq') {
					filters[name] = moment(filter.value, 'L').toISOString();
				} else if (filter.operator === 'inrange') {
					if (!filter.value?.start || filter.value?.start === '-' || filter.value?.start === '') {
						return;
					}
					if (!filter.value?.end || filter.value?.end === '-' || filter.value?.end === '') {
						return;
					}
					filters[name] = [moment(filter.value.start, 'L').toISOString(), moment(filter.value.end, 'L').toISOString()];
				}
			}
		});
		return filters;
	}

	public static getHttpParams(pageable: Pageable, isTable = true): string {
		let params = `size=${pageable.limit.toString()}${isTable ? `&page=${pageable.pageNumber?.toString() ?? (pageable.offset / pageable.limit)}` : ''}${!isTable
			? `&offset=${pageable.offset}` : ''}`;
		pageable.sort?.forEach(sortOrder => {
			const val = `${sortOrder.name.toString()},${sortOrder.direction}`;
			params += `&sort=${val}`;
		});
		const getHttpParamsFromObj = (key, value): string => {
			let val = value;
			if (val instanceof Date) {
				const dt = val;
				val = dt.toISOString();
			}
			return `&${key}=${typeof val === 'string' && val.indexOf('&&') !== -1
				? val
					.split('&&')
					.map((l) => {
						const values = l.split('=');
						if (values.length === 2) {
							values[1] = encodeURIComponent(values[1]);
						}
						return values.join('=');
					})
					.join('&&')
				: encodeURIComponent(val)}`;
		};
		const filters = SpringUtils.getFilters(pageable.filters);
		if (filters) {
			Object.keys(filters).forEach((key) => {
				const value = filters[key];
				if (Array.isArray(value)) {
					for (const item of value) {
						params += getHttpParamsFromObj(key, item);
					}
					return;
				}
				params += getHttpParamsFromObj(key, value);
			});
		}
		return params;
	}
}
