import { forwardRef, MutableRefObject, ReactNode, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeColumn, TypeComputedProps, TypeDataGridProps, TypeSingleFilterValue as TypeSingleFilterValueBase, TypeSingleSortInfo as TypeSingleSortInfoBase } from '@inovua/reactdatagrid-community/types';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import * as yup from 'yup';
import { IResult, Pageable, Page, SortOrder } from '@astick/core';
import { Formik } from 'formik';
import { AutocompleteForm, CoordinatesForm, InputForm, SelectForm } from './forms';
import { useTheme } from '../hooks/useTheme';
import { FormikProvider } from '../contexts';

import '../styles/table.control.scss';

export type TableRef = {
	getTable: () => TypeComputedProps | undefined;
};

export type TypeSingleSortInfo = TypeSingleSortInfoBase & {
	/** Путь к атрибуту для запросов сортировки и фильтрации */
	path?: string;
}

export type TypeSingleFilterValue = Omit<TypeSingleFilterValueBase, 'value'> & {
	/** Путь к атрибуту для запросов сортировки и фильтрации */
	path?: string;
	value?: any;
	getData?: () => Promise<{
		id: string;
		label: string;
	}[]> | {
		id: string;
		label: string;
	}[];
}

export type TableTypeColumn = Omit<TypeColumn, 'name' | 'type'> & {
	name: string;
	/** Путь к атрибуту для запросов сортировки и фильтрации */
	path?: string;
	type?: 'text' | 'textarea' | 'boolean' | 'number' | 'date' | 'datetime' | 'autocomplete' | 'dropdown' | 'coordinates';
	creatable?: boolean;
	creatableHidden?: boolean;
	editableHidden?: boolean;
	data?: any[];
	getData?: (searchText?: string) => Promise<{
		label: string;
		value: string;
	}[] | undefined>;
	readonly renderInDialog?: (column: TableTypeColumn, disabled: boolean, ...args: any[]) => ReactNode;
	rowInDialog?: number;
}

export type TableProps<T> = Omit<TypeDataGridProps, 'dataSource' | 'columns' | 'defaultSortInfo' | 'filterValue'> & {
	openable?: boolean;
	deletable?: boolean;
	load?: (pageable: Pageable) => Promise<Page<T> | undefined>;
	columns: TableTypeColumn[];
	creatable?: boolean;
	editableCells?: boolean;
	schema?: yup.ObjectSchema<any>;
	defaultSortInfo?: TypeSingleSortInfo[];
	filterValue?: TypeSingleFilterValue[] | null;
	onSubmit?: (operation: 'info' | 'create' | 'edit' | 'delete', newData, initData) => Promise<IResult>;
};

export type TypeI18n = NonNullable<TypeDataGridProps['i18n']>;

export type TableOptions<T> = TableProps<T> & {
	i18n?: TypeI18n;
};

export const tableOptions: TableOptions<any> = {
	defaultLimit: 20,
	pageSizes: [20, 50, 100],
	columns: [],
	i18n: {
		pageText: 'Страница ',
		ofText: ' из ',
		perPageText: 'Результатов на страницу',
		showingText: 'Показаны ',
		showFilteringRow: 'Показать строку фильтров',
		hideFilteringRow: 'Скрыть строку фильтров',
		enable: 'Разрешить',
		disable: 'Запретить',
		clear: 'Очистить',
		clearAll: 'Очистить всё',
		columns: 'Столбцы',
		sortAsc: 'Сортировать по возрастанию',
		sortDesc: 'Сортировать по убыванию',
		unsort: 'Не сортировать',
		contains: 'Содержит',
		startsWith: 'Начинается с',
		endsWith: 'Заканчивается на',
		notContains: 'Не содержит',
		inlist: 'В списке',
		notinlist: 'Нет в списке',
		neq: 'Не равно',
		inrange: 'В диапазоне',
		notinrange: 'Вне диапазона',
		eq: 'Равно',
		notEmpty: 'Не пусто',
		empty: 'Пусто',
		lt: 'Меньше',
		lte: 'Меньше или равно',
		gt: 'Больше',
		gte: 'Больше или равно',
		before: 'До',
		beforeOrOn: 'До или в',
		afterOrOn: 'После или в',
		after: 'После',
		start: ' ',
		end: ' ',
		noRecords: 'Записи отсутствуют',
	},
};

type Editor = {
	showing: boolean;
	operation?: 'info' | 'create' | 'edit';
	data?: any;
};

export const getTableValue = (column: TableTypeColumn, value) => {
	if (column.type === 'boolean') {
		return <div style={{ textAlign: 'center' }}><input type='checkbox' disabled={true} checked={!!value} /></div>;
	}
	if (column.type === 'number') {
		return <div>{value !== undefined && value !== null ? value : '-'}</div>;
	}
	if (column.type === 'coordinates') {
		return <div>{value?.longitude && value?.latitude ? `${value.longitude}, ${value.latitude}` : '-'}</div>;
	}
	if (!column.name || !value) {
		return '-';
	}
	if (column.type === undefined || column.type === 'text') {
		value = value.toString();
	}
	if (column.type && ['date', 'datetime'].includes(column.type)) {
		return moment(value).format(column.dateFormat ?? column.type === 'datetime' ? 'L LTS' : 'L');
	}
	const v = typeof value === 'string' ? value : typeof value === 'boolean' ? (value ? 'true' : 'false') : value?.toString();
	return <div title={v}>{v ?? '-'}</div>;
};

const ControlDialog = ({ column, editor }: { column: TableTypeColumn; editor: Editor }) => {
	if (editor.operation === 'create' && column.creatableHidden) {
		return null;
	} else if (editor.operation === 'edit' && column.editableHidden) {
		return null;
	}
	const disabled = editor.operation === 'info'
		|| (editor.operation === 'create' && column.creatable === false)
		|| (editor.operation === 'edit' && column.editable === false);
	if (column.renderInDialog) {
		return <div>{column.renderInDialog(column, disabled)}</div>;
	}
	if (column.type === 'autocomplete') {
		return <AutocompleteForm name={column.name} label={column.header} disabled={disabled}
			options={column.data} getData={column.getData!} />;
	}
	else if (column.type === 'dropdown') {
		return <SelectForm name={column.name} label={column.header} disabled={disabled} options={column.data} getData={column.getData!} />;
	} else if (column.type === 'coordinates') {
		return <CoordinatesForm name={column.name} label={column.header} disabled={disabled} map={{
			isGeocoderApi: true,
			isFullscreen: true,
			isNavigationControl: true,
			isUserLocation: true,
		}} />;
	}
	return <InputForm type={column.type === 'boolean' ? 'checkbox' : (column.type as any ?? 'text')}
		name={column.name} label={column.header} disabled={disabled} />;
};

const ControlDialogWithColumn = ({ column, editor, isColumn }: { column: TableTypeColumn; editor: Editor, isColumn: boolean }) => {
	return isColumn ? <Col><ControlDialog column={column} editor={editor} /></Col> : <ControlDialog column={column} editor={editor} />;
};

const EditorDialog = ({ columns, handleClose, editor, refTable, schema, onSubmit }: {
	columns: TableTypeColumn[];
	handleClose: () => void;
	editor: Editor;
	refTable?: MutableRefObject<TypeComputedProps>;
	schema?: yup.ObjectSchema<any>;
	onSubmit?: (operation: 'info' | 'create' | 'edit', newData, initData) => Promise<IResult>;
}) => {
	const initialValues: any = {};
	columns?.forEach(cl => {
		if (editor.operation === 'create') {
			initialValues[cl.name] = (schema?.fields[cl.name] as any)?.spec.default ?? '';
			return;
		}
		initialValues[cl.name] = editor.data?.[cl.name] ?? (cl.type === 'boolean' ? false : '');
	});

	const submit = useRef<any>();

	return <>
		<Modal.Header closeButton>
			<Modal.Title>{editor.operation === 'info' ? 'Информация' : editor.operation === 'create' ? 'Добавление' : 'Изменение'}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<FormikProvider validationSchema={schema}>
				<Formik
					validationSchema={schema}
					initialValues={initialValues}
					onSubmit={async (data) => {
						if (!onSubmit)
							return;
						columns.forEach(col => {
							if (editor.operation === 'create' && (col.creatableHidden || col.creatable === false)
								|| editor.operation === 'edit' && (col.editableHidden || col.editable === false)) {
								delete data[col.name];
							}
							if (editor.operation && ['create', 'edit'].includes(editor.operation) && col.type === 'text' && data[col.name] === '') {
								delete data[col.name];
							}
						});
						if (editor.operation === 'edit') {
							const idProperty = refTable?.current.idProperty ?? 'id';
							data[idProperty] = editor.data?.[idProperty];
						}
						const r = await onSubmit(editor.operation!, data, editor.data);
						if (!r.success)
							return;
						refTable?.current.reload();
						handleClose();
					}}
				>
					{({ handleSubmit }) => {
						submit.current = handleSubmit;
						return (
							<Form noValidate onSubmit={handleSubmit}>
								{columns?.reduce((cols, cl) => {
									if (cl.rowInDialog !== undefined) {
										if (cols.rowIndex > -1 && cols.rowIndex === cl.rowInDialog) {
											cols.rows[cols.rows.length - 1].push(cl);
										} else {
											cols.rowIndex = cl.rowInDialog;
											cols.rows.push([cl]);
										}
									} else {
										cols.rows.push([cl]);
									}
									return cols;
								}, {
									rowIndex: -1,
									rows: [],
								} as {
									rowIndex: number;
									rows: Array<TableTypeColumn[]>;
								}).rows.map((cols, indexRow) => {
									return cols.length > 1 ?
										<Row key={indexRow} className="mb-3">
											{cols.map((cl, indexControl) => {
												return <ControlDialogWithColumn key={indexControl} column={cl} editor={editor} isColumn={true} />;
											})}
										</Row> : <div key={indexRow}>
											{cols.map((cl, indexControl) => {
												return <ControlDialogWithColumn key={indexControl} column={cl} editor={editor} isColumn={false} />;
											})}
										</div>;
								})}
							</Form>
						);
					}}
				</Formik >
			</FormikProvider>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={handleClose}>
				Отмена
			</Button>
			<Button type="submit" variant="primary" onClick={() => submit.current()}>
				{editor.operation === 'info' ? 'Ок' : editor.operation === 'create' ? 'Добавить' : 'Изменить'}
			</Button>
		</Modal.Footer>
	</>;
};

export const Table = forwardRef<TableRef, TableProps<any>>((props, ref) => {
	props = { ...tableOptions, ...props };
	const theme = useTheme();
	const tableRef = useRef<MutableRefObject<TypeComputedProps>>();
	const [filterValue, setFilterValue] = useState(props.filterValue);

	const load = ({ skip, limit, sortInfo, filterValue }: { skip: number, limit: number, sortInfo?: TypeSingleSortInfo[], filterValue: TypeSingleFilterValue[] }) => {
		if (!props.load) {
			return Promise.resolve({ data: [], count: 0 });
		}
		return props.load
			.call(undefined, {
				offset: skip,
				limit,
				filters: filterValue,
				sort: (Array.isArray(sortInfo) ? sortInfo : sortInfo ? [sortInfo] : []).map((sort) => (
					{
						name: sort.path ?? sort.name,
						direction: sort.dir === 1 ? 'asc' : 'desc',
					} as SortOrder)),
			})
			.then((data) => {
				return Promise.resolve({ data: data?.items ?? [], count: data?.totalSize ?? 0 });
			});
	};

	const dataSource = useCallback(load, [props.load]);

	const [editor, setEditor] = useState<Editor>({
		showing: false,
	});

	const handleClose = () => setEditor({
		showing: false,
	});

	useImperativeHandle(ref, () => ({
		getTable: () => tableRef.current?.current,
	}));

	return (
		<div>
			{props.creatable ? <Button title="Добавить" onClick={() => setEditor({
				showing: true,
				operation: 'create',
			})}>Добавить</Button> : null}
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={editor.showing} onHide={handleClose}>
				{props.columns ? <EditorDialog columns={props.columns} schema={props.schema} editor={editor}
					refTable={tableRef.current} handleClose={handleClose} onSubmit={props.onSubmit} /> : null}
			</Modal>
			<ReactDataGrid
				{...props}
				className={`${props.className}${props.pagination ? ' pagination' : ''}`}
				editable={!!props.editableCells}
				handle={(gridApiRef: any) => {
					tableRef.current = gridApiRef;
				}}
				theme={`default-${theme && theme.name !== 'default' ? theme.name : 'light'}`}
				columns={props.columns?.map((col) => {
					const filter = filterValue?.find((f) => f.name === col.name);
					const column = {
						...col,
						filterEditor: !filter ? col.filterEditor : filter.type === 'boolean' || col.type === 'boolean' ? BoolFilter :
							filter.type === 'number' || col.type === 'number' ? NumberFilter :
								filter.type === 'select' ? SelectFilter :
									filter.type === 'date' ? DateFilter :
										col.filterEditor,
						filterDelay: !col.type || col.type === 'text' || col.type === 'number' || col.type === 'date' ? 500 : 0,
					};
					if (!column.filterEditorProps) {
						if (filter?.type === 'select') {
							column.filterEditorProps = (props) => {
								return {
									dataSource: props.filterValue.getData,
								};
							};
						}
					}
					// if (col.type === 'dropdown') {
					// 	column.type = 'text';
					// 	if (filter?.type === 'select') {
					// 		if (filter && ['inlist', 'notinlist'].includes(filter.operator)) {
					// 			column.filterEditorProps = (props, item) => {
					// 				return {
					// 					...col.filterEditorProps!(props, item),
					// 					multiple: true,
					// 					wrapMultiple: false,
					// 				};
					// 			};
					// 		}
					// 	}
					// }
					if (!column.render) {
						column.render = (item) => getTableValue(column, item.value);
					}
					return column;
				})}
				dataSource={dataSource}
				filterValue={filterValue as any}
				onFilterValueChange={(filterValue: any) => {
					const filters: TypeSingleFilterValue[] = filterValue;
					filters?.forEach(filter => {
						const col = tableRef.current?.current.columnsMap?.[filter.name] as TableTypeColumn;
						filter.path = col?.path;
					});
					setFilterValue(filterValue);
				}}
				onSortInfoChange={(si: any) => {
					const sortInfos: TypeSingleSortInfo[] = si;
					sortInfos?.forEach(sortInfo => {
						const col = tableRef.current?.current.columnsMap?.[sortInfo.columnName ?? sortInfo.name] as TableTypeColumn;
						sortInfo.path = col?.path;
					});
				}}
				renderRowContextMenu={props.openable || props.editable || props.deletable ? (menuProps, details) => {
					const { rowProps } = details;
					const itemData = rowProps.data;
					if (!itemData) {
						return;
					}
					menuProps.autoDismiss = true;
					const items: any[] = [];
					if (props.openable) {
						items.push({
							label: 'Открыть',
							onClick: () => {
								setEditor({
									showing: true,
									operation: 'info',
									data: itemData,
								});
							},
						});
					}
					if (props.editable) {
						items.push({
							label: 'Редактировать',
							onClick: () => {
								setEditor({
									showing: true,
									operation: 'edit',
									data: itemData,
								});
							},
						});
					}
					if (props.deletable) {
						items.push({
							label: 'Удалить',
							onClick: async () => {
								if (window.confirm(`Вы действительно хотите удалить ${itemData[props.idProperty ?? 'id'] ?? 'запись?'}`)) {
									if (!props.onSubmit)
										return;
									const r = await props.onSubmit?.('delete', null, itemData);
									if (!r.success)
										return;
									tableRef.current?.current.reload();
									handleClose();
								}
							},
						});
					}
					if (items.length > 0) {
						menuProps.items = items;
					}
					props.renderRowContextMenu?.(menuProps, details);
				} : undefined}
			/>
		</div>
	);
});

Table.displayName = 'Table';
