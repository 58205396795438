import { Identity } from '@astick/security-core';

export class User implements Identity {
	public id!: string;
	public username!: string;
	public lastName?: string;
	public firstName!: string;
	public middleName?: string;
	public email?: string;
	public mobile?: string;
	public active!: boolean;
	public comment?: string;

	private _isAdmin = false;
	private _isManager = false;
	private _isClient = false;
	private _isService = false;
	private _roles!: string[];

	constructor(identity?: Identity) {
		Object.assign(this, identity);
	}

	public get isAdmin() {
		return this._isAdmin;
	}

	public get isManager() {
		return this._isManager;
	}

	public get isClient() {
		return this._isClient;
	}

	public get isService() {
		return this._isService;
	}

	public set roles(roles: string[]) {
		if (roles && roles.length > 0) {
			this._isAdmin = roles.indexOf('admin') > -1;
			this._isManager = roles.indexOf('manager') > -1;
			this._isClient = roles.indexOf('client') > -1;
			this._isService = roles.indexOf('service') > -1;
		}
		this._roles = roles;
	}

	public get roles() {
		return this._roles;
	}

	public get initials() {
		return User.getInitials(this);
	}

	static getInitials(user: User): string | undefined {
		return user?.lastName && user?.firstName ?
			user.lastName + ' ' + user.firstName[0].toUpperCase() + '.' + (user.middleName ? ` ${user.middleName[0].toUpperCase()}.` : '') : '-';
	}

	public get fio() {
		return User.getFio(this);
	}

	static getFio(user: User): string | undefined {
		return user?.lastName && user?.firstName ? `${user.lastName} ${user.firstName}${user.middleName ? ` ${user.middleName}` : ''}` : '-';
	}

	static clone(userSource: User): User | undefined {
		if (!userSource) {
			return undefined;
		}

		const user = new User();
		Object.assign(user, userSource);

		return user;
	}
}
