// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control svg, button svg {
  fill: var(--bs-body-color);
}

.form-control-date-empty-value {
  color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/base.scss","webpack://./src/lib_ui/src/styles/datePicker.control.scss"],"names":[],"mappings":"AAGI;EACI,0BAAA;ACFR;;AAAA;EACG,WDHiB;ACMpB","sourcesContent":["$placeholder_color: #aaa;\n\n.form-control, button {\n    svg {\n        fill: var(--bs-body-color);\n    }\n}","@import './base.scss';\n\n.form-control-date-empty-value {\n  \tcolor: $placeholder_color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
