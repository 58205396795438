import { useCallback, useEffect } from 'react';
import { IResult } from '@astick/core';
import { Table, TableTypeColumn } from '@astick/ui';
import { columnsUL, Franchisees, franchiseesSchema } from 'src/models';
import { useLoading } from 'src/hooks';
import { PvzService } from 'src/services';
import { useToast } from '../../../base/hooks';
import { SelectUserForm } from '../../components/forms';

export const FranchiseesPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const columns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{ name: 'code', type: 'text', header: 'Код', minWidth: 100 },
		{ name: 'userId', type: 'text', header: 'Пользователь', visible: false, renderInDialog: (column, disabled) => {
			return <SelectUserForm name={column.name} label={column.header} disabled={disabled} />;
		}},
		{ name: 'fee', type: 'number', header: 'Комиссия', visible: false },
		...columnsUL,
	];

	useEffect(() => {
		document.title = 'Франчайзи';
	}, []);

	const loadFranchisees = useCallback(async (pageable) => {
		const r = await PvzService.getFranchisees(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список франчайзи!');
		}
	}, []);

	return <Table
		idProperty="id"
		columns={columns}
		schema={franchiseesSchema}
		sortable
		filterValue={[{
			name: 'roleName',
			operator: 'eq',
			type: 'string',
		}, {
			name: 'discount',
			operator: 'eq',
			type: 'number',
		}]}
		openable
		creatable
		editable
		deletable
		pagination
		showColumnMenuTool
		load={loadFranchisees}
		renderRowContextMenu={(menuProps, { rowProps }) => {
			const item = rowProps.data as Franchisees;
			if (!item) {
				return;
			}
			menuProps.autoDismiss = true;
			menuProps.items.splice(1, 0, {
				label: 'Активировать',
				onClick: async () => {
					const r = await PvzService.deleteFranchisees(item.id);
					if (!r.success) {
						toast.addError(`Не удалось активировать франчайзи "${item.briefName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно активировалось франчайзи "${item.briefName}"`);
					}
				},
			},
			{
				label: 'Заблокировать',
				onClick: async () => {
					const r = await PvzService.deleteFranchisees(item.id);
					if (!r.success) {
						toast.addError(`Не удалось заблокировать франчайзи "${item.briefName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно заблокировалось франчайзи "${item.briefName}"`);
					}
				},
			},
			{
				label: 'Разблокировать',
				onClick: async () => {
					const r = await PvzService.deleteFranchisees(item.id);
					if (!r.success) {
						toast.addError(`Не удалось разблокировать франчайзи "${item.briefName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно разблокировалось франчайзи "${item.briefName}"`);
					}
				},
			});
		}}
		onSubmit={async (operation, data: Franchisees) => {
			setLoading(true);
			let r: IResult | undefined;
			switch (operation) {
				case 'create':
					r = await PvzService.createFranchisees(data);
					if (!r.success) {
						toast.addError(`Не удалось добавить новый франчайзи "${data.briefName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно добавлена новый франчайзи "${data.briefName}"`);
					}
					break;
				case 'edit':
					r = await PvzService.updateFranchisees(data);
					if (!r.success) {
						toast.addError(`Не удалось изменить франчайзи "${data.briefName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно изменен франчайзи "${data.briefName}"`);
					}
					break;
				default:
					r = {
						success: false,
					};
			}
			setLoading(false);
			return r;
		}}
	/>;
};
