import { IHttpResult } from '@astick/core';
import { AccountService } from '../services/accounts.service';

export const HttpRepository = {
	getRequestInit: (
		init?: Omit<RequestInit, 'body'> & {
			body?: any;
		},
	): RequestInit | undefined => {
		const token = AccountService.getToken();
		const request: any = { ...init ?? {} };
		request.headers = token
			? {
				...init?.headers,
				Authorization: `Bearer ${AccountService.getToken()?.accessToken}`,
			}
			: {
				...init?.headers,
			};
		if (typeof request.body === 'object' && !request.headers['Content-Type'] && !(request.body instanceof FormData)) {
			request.headers['Content-Type'] = 'application/json';
		}
		const body = request.body;
		if (request.body && typeof request.body === 'object' && !(request.body instanceof FormData)) {
			request.body = JSON.stringify(body);
		}
		return request;
	},
	getResult: async <T>(resp: Response): Promise<IHttpResult<T>> => {
		if ([401, 402, 403].includes(resp.status)) {
			// AccountService.signOut();
			return {
				code: resp.status,
				success: false,
				response: resp,
			};
		}
		let txt: any = await resp.text();
		try {
			txt = JSON.parse(txt);
			// eslint-disable-next-line no-empty
		} catch { }
		const getSuccess = () => {
			return resp.status === 200 || resp.status === 201;
		};
		const result = {
			success: getSuccess(),
			code: txt?.code ?? txt?.message?.code ?? resp.status,
			error: getSuccess() ? '' : txt?.error ?? txt?.text ?? txt?.message ?? txt?.message?.error ?? txt,
			response: resp,
			value: getSuccess() ? txt : '',
		};
		return result;
	},
};
