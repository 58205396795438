import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HTMLUtils } from '@astick/core';
import { ControlsIcons } from '../resources';

export type HintProps = {
	title?: string;
	text: string;
	readonly?: boolean;
	disabled?: boolean;
	onClick?: (event) => void;
};

export const Hint = (props: HintProps) => {
	const title = props.title ?? 'Справочная информация';

	// Защита от XSS
	if (HTMLUtils.hasScript(props.text)) {
		console.warn('В html тексте имеются скрипты! HTML:\n', props.text);
		return null;
	}

	return (
		<div className="clickable">
			<OverlayTrigger
				trigger="click"
				placement="left"
				overlay={
					<Popover>
						<Popover.Header as="h3">{title}</Popover.Header>
						<Popover.Body>
							<div dangerouslySetInnerHTML={{ __html: props.text }} />
						</Popover.Body>
					</Popover>
				}
			>
				<div><ControlsIcons type="hint" /></div>
			</OverlayTrigger>
		</div>
	);
};
