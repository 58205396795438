import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import maplibregl, { NavigationControl, Popup } from 'maplibre-gl';
import { Map, MapInstance, MapRef } from '@vis.gl/react-maplibre';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import layersTheme from 'protomaps-themes-base';
import { Address, PageableUtils } from '@astick/core';
import { AddressUtils } from '@astick/ui';
import { Pvz } from 'src/models';
import { PvzService } from 'src/services';
import { useToast } from 'src/hooks';

import 'maplibre-gl/dist/maplibre-gl.css';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';


const PvzInfo = ({ pvz, address }: {
	pvz: Pvz, address: Address
}) => {
	console.log('🚀 ~ PvzInfo ~ pvz:', pvz);
	return <div>{AddressUtils.getAddressString(address)}</div>;
};

const geocoderApi = {
	forwardGeocode: async (config) => {
		const features: any[] = [];
		try {
			const request = `https://astick.ru/api/maps/geocoding/search/byText?text=${config.query}`;
			const response = await fetch(request);
			const addresses = await response.json() as Address[];
			for (const address of addresses) {
				const center = [
					address.coordinates?.longitude,
					address.coordinates?.latitude,
				];
				const addressText = AddressUtils.getAddressString(address, false, true);
				const point = {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: center,
					},
					place_name: addressText,
					properties: address,
					text: addressText,
					place_type: ['place'],
					center,
				};
				features.push(point);
			}
		} catch (e) {
			console.error(`Failed to forwardGeocode with error: ${e}`);
		}
		return {
			features,
		};
	},
};

export const MapsPage = () => {
	const toast = useToast();
	const [map, setMap] = useState<MapInstance>();
	const [pvzList, setPvzList] = useState<Pvz[]>();
	const mapRef = useRef<MapRef>();

	const initMap = () => {
		if (!map) {
			return;
		}
		map.addControl(
			new MaplibreGeocoder(geocoderApi as any, {
				maplibregl,
			}),
		);

		map.addControl(new maplibregl.FullscreenControl());
		const nav = new NavigationControl();
		map.addControl(nav, 'top-left');


		map.addControl(
			new maplibregl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				trackUserLocation: true,
			}),
		);


		// map.on('mousemove', (e) => {
		// 	// console.log('🚀 ~ map.on ~ e:', e);
		// 	const features = map.queryRenderedFeatures(e.point);
		// 	console.log('🚀 ~ map.on ~ features:', features);
		// });

		const popup = new Popup();
		popup.on('open', () => {
			console.log('popup was opened');
		}).addTo(map);

		map.on('click', 'pvz', async (e) => {
			const pvzTxt = e.features?.[0].properties.pvz;
			if (!pvzTxt) {
				return;
			}
			const pvz = JSON.parse(pvzTxt);
			const url = `https://astick.ru/api/maps/geocoding/search/byCoordinate?lng=${pvz.address.coordinates?.longitude}&lat=${pvz.address.coordinates?.latitude}`;
			const response = await fetch(url);
			const address = await response.json() as Address;
			new maplibregl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(renderToString(<PvzInfo pvz={pvz} address={address} />))
				.addTo(map);
		});
		map.on('mouseenter', 'pvz', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		map.on('mouseleave', 'pvz', () => {
			map.getCanvas().style.cursor = '';
		});
	};

	useEffect(() => {
		const init = async () => {
			const r = await PvzService.getPvz(PageableUtils.getEmptyPageable());
			if (r.success) {
				setPvzList(r.value!.items);
			} else {
				toast.addError('Не удалось получить список пунктов выдачи заказов!');
			}
		};
		init().then();
	}, []);

	useEffect(() => {
		if (!map || !pvzList) {
			return;
		}
		const pvz = map.getSource('pvz');
		if (pvz) {
			map.removeSource('pvz');
		}
		map.addSource('pvz', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: pvzList.map(pvz => ({
					type: 'Feature',
					properties: {
						// description:
						// 		'<strong>Make it Mount Pleasant</strong><p><a href="http://www.mtpleasantdc.com/makeitmtpleasant" target="_blank" title="Opens in a new window">Make it Mount Pleasant</a> is a handmade and vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>',
						pvz,
						// icon: 'theatre',
						// icon: 'shop',
						icon: 'bus_stop',
					},
					geometry: {
						type: 'Point',
						coordinates: [47.22243731797411, 56.137349723855465],
					},
				})),
			},
		});
		const layer = map.getLayer('pvz');
		if (layer) {
			map.removeLayer('pvz');
		}
		map.addLayer({
			id: 'pvz',
			type: 'symbol',
			source: 'pvz',
			layout: {
				'icon-image': '{icon}',
				'icon-overlap': 'always',
			},
		});
		// map.setCenter([37.6156, 55.7522]);
		// map.setCenter([20.503939963628625, 54.71055304418087]);
		// map.setCenter([20.503939963628625, 54.71055304418087]);
		// map.setZoom(5);
		initMap();
	}, [map, pvzList]);

	return (
		<div className="App">
			<Map
				ref={(ref) => {
					if (ref) {
						mapRef.current = ref;
						const map = ref.getMap();
						map.on('load', () => {
							setMap(ref.getMap());
						});
					}
				}}
				mapLib={maplibregl}
				style={{ width: '100%', height: 600 }}
				mapStyle={{
					version: 8,
					glyphs: 'https://astick.ru/api/maps/fonts/{fontstack}/{range}.pbf',
					sprite: 'https://astick.ru/api/maps/sprites/v4/light',
					// center: [37.6156, 55.7522], // Москва
					center: [47.22409492310956, 56.1373490232842], // Home
					zoom: 16,
					projection: {
						type: 'globe',
					},
					sources: {
						protomaps: {
							type: 'vector',
							url: 'https://astick.ru/api/maps/tiles/tiles.json',
							attribution: '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
						},
					},
					layers: [
						...layersTheme('protomaps', 'light', 'ru'),
						{
							id: 'housenumbers',
							type: 'symbol',
							source: 'protomaps',
							'source-layer': 'buildings',
							minzoom: 14,
							layout: {
								'text-field': ['get', 'house_number'],
								'text-font': ['Noto Sans Regular'],
								'text-size': 10,
								'text-anchor': 'center',
								'text-offset': [0, 0.1],
							},
							paint: {
								'text-color': '#333',
								'text-halo-color': '#fff',
								'text-halo-width': 1,
							},
						},
					],
				}}
			/>
		</div>
	);
};
