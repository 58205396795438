import { IHttpResult, Pageable, Page } from '@astick/core';
import { SpringUtils } from '@astick/ui';
import { config } from '../config';
import { HttpRepository } from './http.repository';
import { User } from '../models';

export class UsersRepository {
	static async getUser(userId: string): Promise<IHttpResult<User>> {
		const resp = await fetch(`${config.apiUrlAccount}/users/info?id=${userId}`, HttpRepository.getRequestInit({ method: 'get' }));
		return await HttpRepository.getResult<User>(resp);
	}

	static async getUsers(pageable: Pageable): Promise<IHttpResult<Page<User>>> {
		const p = { ...pageable };
		p.filters = undefined;
		const filters: string[] = [];
		if (pageable.filters) {
			const f = SpringUtils.getFilters(pageable.filters, true);
			if (f) {
				Object.keys(f).forEach(key => {
					filters.push(`${key}:${f[key]}`);
				});
			}
		}
		const resp = await fetch(`${config.apiUrlAccount}/users?${SpringUtils.getHttpParams(p)}${filters.length > 0 ?
			`&filter=${filters.join(' ')}` : ''}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<any>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async deleteUser(userId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlAccount}/users?id=${userId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}
};
