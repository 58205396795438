import { IHttpResult, Pageable, Page } from '@astick/core';
import { SpringUtils } from '@astick/ui';
import { config } from '../config';
import { Franchisees, Pvz } from '../models';
import { HttpRepository } from './http.repository';

export class PvzRepository {
	//#region Franchisees

	static async getFranchisees(pageable: Pageable): Promise<IHttpResult<Page<Franchisees>>> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees?${SpringUtils.getHttpParams(pageable)}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<Franchisees[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createFranchisees(franchisees: Franchisees): Promise<IHttpResult<Franchisees>> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees`, HttpRepository.getRequestInit({ method: 'post', body: franchisees }));
		return await HttpRepository.getResult<Franchisees>(resp);
	}

	static async updateFranchisees(franchisees: Franchisees): Promise<IHttpResult<Franchisees>> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees`, HttpRepository.getRequestInit({ method: 'put', body: franchisees }));
		return await HttpRepository.getResult<Franchisees>(resp);
	}

	static async deleteFranchisees(franchiseesId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees?id=${franchiseesId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}

	static async activateFranchisees(franchiseesId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees/activate?id=${franchiseesId}`, HttpRepository.getRequestInit({ method: 'put' }));
		return await HttpRepository.getResult(resp);
	}

	static async deactivateFranchisees(franchiseesId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees/deactivate?id=${franchiseesId}`, HttpRepository.getRequestInit({ method: 'put' }));
		return await HttpRepository.getResult(resp);
	}

	static async lockFranchisees(franchiseesId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees/lock?id=${franchiseesId}`, HttpRepository.getRequestInit({ method: 'put' }));
		return await HttpRepository.getResult(resp);
	}

	static async unlockFranchisees(franchiseesId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlShop}/franchisees/unlock?id=${franchiseesId}`, HttpRepository.getRequestInit({ method: 'put' }));
		return await HttpRepository.getResult(resp);
	}

	//#endregion
	
	static async getPvz(_pageable: Pageable): Promise<IHttpResult<Page<Pvz>>> {
		return {
			success: true,
			value: SpringUtils.getPage({
				success: true,
				value: [{
					id: '1',
					code: '00001',
					userId: '000000',
					// address: {
					// 	coordinates: {
					// 		latitude: 56.137349723855465,
					// 		longitude: 47.22243731797411,
					// 	},
					// },
				}, {
					id: '2',
					code: '00002',
					userId: '000000',
					// address: {
					// 	coordinates: {
					// 		latitude: 56.136954656435876,
					// 		longitude: 47.220301274323134,
					// 	},
					// },
				}] as Pvz[],
			}),
		};
	}

	static async createPvz(_pvz: Pvz): Promise<IHttpResult<Pvz>> {
		throw new Error('createPvz');
	}

	static async updatePvz(_pvz: Pvz): Promise<IHttpResult<Pvz>> {
		throw new Error('updatePvz');
	}

	static async deletePvz(_pvzId: string): Promise<IHttpResult> {
		throw new Error('deletePvz');
	}
};
