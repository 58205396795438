import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Page, Pageable } from '@astick/core';
import { Table, TableRef, TableTypeColumn } from '@astick/ui';
import { User } from 'src/models';
import { useToast } from '../../base/hooks';
import { UsersService } from '../services';

type SelectUserWidgetProps = {
	onSubmit: (user: User) => void;
}

export type SelectUserWidgetRef = {
	open: (title: string, user?: User) => void;
	close: () => void;
};

export const SelectUserWidget = forwardRef<SelectUserWidgetRef, SelectUserWidgetProps>((props, ref) => {
	const toast = useToast();
	const tableUsersRef = useRef<TableRef>(null);

	const [showUsers, setShowUsers] = useState<{
		showing: boolean,
		title: string,
		user?: User,
	}>({
		showing: false,
		title: 'Пользователи',
	});

	const [userSelected, setUserSelected] = useState<{
		[p: string]: User
	}>({});

	useImperativeHandle(ref, () => ({
		open: (title, user) => {
			if (user) {
				setUserSelected({
					[user.id]: user,
				});
			}
			setShowUsers({
				showing: true,
				title,
				user,
			});
		},
		close: () => {
			handleClose();
		},
	}));

	const usersColumns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{ name: 'username', type: 'text', header: 'Логин' },
		{ name: 'lastName', type: 'text', header: 'Фамилия', minWidth: 170 },
		{ name: 'firstName', type: 'text', header: 'Имя', minWidth: 170 },
		{ name: 'surName', type: 'text', header: 'Отчество', minWidth: 170 },
		{ name: 'email', type: 'text', header: 'Email', minWidth: 200 },
		{ name: 'mobile', type: 'text', header: 'Мобильный', minWidth: 100 },
		{ name: 'city', type: 'text', header: 'Город', minWidth: 100 },
	];

	const handleClose = () => setShowUsers({
		showing: false,
		title: 'Пользователи',
	});

	const loadUsers = useCallback(async (pageable: Pageable): Promise<Page<User> | undefined> => {
		if (!showUsers.showing) {
			return;
		}
		const r = await UsersService.getUsers(pageable);
		if (!r.success) {
			toast.addError('Не удалось получить список пользователей!');
			return;
		}
		return r.value;
	}, [showUsers]);

	return <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showUsers.showing} onHide={handleClose}>
		<Modal.Header closeButton>
			<Modal.Title>{showUsers.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Table
				ref={tableUsersRef}
				idProperty="id"
				columns={usersColumns}
				filterValue={[{
					name: 'username',
					operator: 'contains',
					type: 'string',
				}, {
					name: 'lastName',
					operator: 'contains',
					type: 'string',
				},
				{
					name: 'firstName',
					operator: 'contains',
					type: 'string',
				},
				{
					name: 'surName',
					operator: 'contains',
					type: 'string',
				},
				{
					name: 'email',
					operator: 'contains',
					type: 'string',
				},
				{
					name: 'mobile',
					operator: 'contains',
					type: 'string',
				},
				{
					name: 'city',
					operator: 'contains',
					type: 'string',
				}]}
				pagination
				load={loadUsers}
				selected={userSelected}
				onSelectionChange={(sel) => {
					setUserSelected(sel.selected ?? {} as any);
				}}
			/>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={handleClose}>
				Отмена
			</Button>
			<Button type="submit" variant="primary" disabled={!userSelected} onClick={async () => {
				const sel = tableUsersRef.current?.getTable()?.computedSelected;
				const userId = (sel ? Object.keys(sel) : [])?.[0];
				const selUser = sel?.[userId];
				if (selUser) {
					props.onSubmit(selUser);
					handleClose();
				}
			}}>
				Выбрать
			</Button>
		</Modal.Footer>
	</Modal>;
});

SelectUserWidget.displayName = 'UsersWidget';
