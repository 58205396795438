import { RouteObject } from 'react-router-dom';
import { DiscountsPage, FranchiseesPage, ProvidersPage, ProvidersRolesPage, PvzPage, RatesPage, RolesPage, UsersPage } from '../pages';
import { BaseLayout } from '../../base/layouts';
import { menu } from './menu';

export const routes: RouteObject[] = [
	{
		path: '/',
		element: <BaseLayout menuItems={menu} />,
		children: [
			{
				path: '',
				element: <UsersPage />,
			},
			{
				path: '/users',
				element: <UsersPage />,
			},
			{
				path: '/users/roles',
				element: <RolesPage />,
			},
			{
				path: 'prices/discounts',
				element: <DiscountsPage />,
			},
			{
				path: 'prices/providers',
				element: <ProvidersPage />,
			},
			{
				path: 'prices/providersRoles',
				element: <ProvidersRolesPage />,
			},
			{
				path: 'prices/ranges',
				element: <RatesPage />,
			},
			{
				path: 'pvz/franchisees',
				element: <FranchiseesPage />,
			},
			{
				path: 'pvz/pvz',
				element: <PvzPage />,
			},
		],
	},
];
