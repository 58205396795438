/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import { UserUL, userULSchema } from '../account/ul.model';
import { Franchisees } from './franchisees.model';

/** Класс модели ПВЗ */
export type Pvz = UserUL & {
	/** ID ПВЗ */
	id: string;

	/** Код ПВЗ */
	code: string;

	/** Дата подачи заявки */
	filingDate: Date;

	/** Дата открытия */
	openingDate: Date;

	/** Владелец ПВЗ */
	userId: string;

	/** Комиссия за услугу */
	fee: number;

	/** Франчайзи */
	franchisees: Franchisees;
}

export const pvzSchema = yup.object({
	...userULSchema,
	code: yup.string().min(3, 'Минимальная длина кода ${min} буквы.').max(30, 'Максимальная длина кода ${min} буквы.').required('Необходимо ввести код'),
	filingDate: yup.date().required('Необходимо ввести дату подачи заявки'),
	openingDate: yup.date().required('Необходимо ввести дату открытия заявки'),
	userId: yup.string().required('Необходимо ввести пользователя'),
	fee: yup.number().min(0, 'Комиссия должна быть положительной').default(0).required('Необходимо ввести комиссию'),
});
